import { Box, Flex, Image, Text } from '@chakra-ui/react';
import Logo from '../../assets/logo.svg';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterCircle,
  AiFillYoutube,
  AiOutlineMail,
  AiOutlinePhone,
} from 'react-icons/ai';
import { BiLogoDiscord, BiMapPin } from 'react-icons/bi';

const data = [
  {
    title: 'Links',
    links: [
      {
        name: 'Home',
        url: '/',
      },
      {
        name: 'About Us',
        url: 'about',
      },
      {
        name: 'Help/FAQ',
        url: 'faq',
      },
      {
        name: 'Contact Us',
        url: 'contact',
      },
      {
        name: 'Privacy policy',
        url: 'privacy-policy',
      },
      {
        name: 'Terms & Conditions',
        url: 'terms',
      },
    ],
  },
  {
    title: 'Contact us',
    links: [
      {
        name: '+234 802 340 9348',
        url: 'tel:+234 802 340 9348',
        icon: (
          <AiOutlinePhone
            style={{ display: 'inline', transform: 'translateY(2px)' }}
          />
        ),
      },
      {
        name: 'hello@gameporte.com',
        url: 'mailto:hello@gameporte.com?subject=Gameporte Support Email',
        icon: (
          <AiOutlineMail
            style={{ display: 'inline', transform: 'translateY(2px)' }}
          />
        ),
      },
      {
        name: '256 Chapman Road STE105-4, Newark, Delaware',
        url: '#',
        icon: (
          <BiMapPin
            style={{ display: 'inline', transform: 'translateY(2px)' }}
          />
        ),
      },
    ],
  },
];

const socials = [
  {
    icon: <AiFillFacebook color="#FFF" fontSize={'1.4rem'} />,
    link: 'https://www.facebook.com/gameporte',
  },
  {
    icon: <AiFillInstagram color="#FFF" fontSize={'1.4rem'} />,
    link: 'https://instagram.com/gameporte?igshid=YmMyMTA2M2Y=',
  },
  {
    icon: <AiFillTwitterCircle color="#FFF" fontSize={'1.4rem'} />,
    link: 'https://twitter.com/gameporte',
  },
  {
    icon: <BiLogoDiscord color="#FFF" fontSize={'1.4rem'} />,
    link: 'https://discord.gg/9FmPYHB7',
  },
  {
    icon: <AiFillYoutube color="#FFF" fontSize={'1.4rem'} />,
    link: 'https://youtube.com/channel/UCoD1MdZ_SZVGakXIJ3WlSEA',
  },
];

const Footer = () => {
  return (
    <Box px={['1rem', '3rem', '6rem']} zIndex={'50 !important'}>
      <Box pt="3rem" pb="2rem">
        <Flex gap="2rem" flexWrap={'wrap'}>
          <Box flex={'4 0 350px'}>
            <Image src={Logo} w={'30%'} />
            <Text
              mt="1.5rem"
              fontSize={'.95rem'}
              maxW="500px"
              lineHeight={'26px'}
              mb="1.2rem"
              color="#FFFFFF"
            >
              Gameporte is not endorsed by, directly affiliated with, maintained
              or sponsored by Apple Inc, Electronic Arts, Activision Blizzard,
              Take-Two Interactive, Microsoft, Xbox, Sony, PlayStation or Epic
              Games. All content, games titles, trade names and/or trade dress,
              trademarks, artwork and associated imagery are trademarks and/or
              copyright material of their respective owners.
            </Text>
            <Flex gap="1rem" alignItems={'center'} mt=".7rem">
              {socials?.map(({ icon, link }) => (
                <a key={link} target="_blank" href={link} rel="noreferrer">
                  {icon}
                </a>
              ))}
            </Flex>
          </Box>

          <Flex flex={'2 0 350px'} gap={'1rem'} flexWrap={'wrap'}>
            {data?.map(({ title, links }) => (
              <Box flex={'1 1 170px'} key={title} textAlign={'left'}>
                <Text
                  fontSize={'1.1rem'}
                  mb="1.2rem"
                  fontWeight={'700'}
                  color="#FFF"
                >
                  {title}
                </Text>
                {links?.map(({ name, url, icon }) => (
                  <Text
                    key={name}
                    onClick={() => (window.location.href = url)}
                    fontSize={'.87rem'}
                    transition={'250ms ease'}
                    _hover={{ color: '#0071FE' }}
                    fontWeight={'500'}
                    cursor={'pointer'}
                    mb="1.5rem"
                    color="#FCFCFD"
                    width={'fit-content'}
                  >
                    {title === 'Links' ? <ChevronRightIcon /> : icon} {name}
                  </Text>
                ))}
              </Box>
            ))}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;
